import { cn } from '~/utils/misc.ts'

export function Cols({
  size,
  children,
  className,
}: {
  size: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12'
  children: React.ReactNode
  className?: string
}) {
  const options: Record<typeof size, string> = {
    '1': 'sm:max-w-[60px] md:max-w-[44px] lg:max-w-[48px] xl:max-w-[72px] 2xl:max-w-[80px]',
    '2': 'sm:max-w-[136px] md:max-w-[120px] lg:max-w-[128px] xl:max-w-[176px] 2xl:max-w-[192px]',
    '3': 'sm:max-w-[212px] md:max-w-[196px] lg:max-w-[208px] xl:max-w-[280px] 2xl:max-w-[304px]',
    '4': 'max-w-full md:max-w-[272px] lg:max-w-[288px] xl:max-w-[384px] 2xl:max-w-[416px]',
    '5': 'max-w-full md:max-w-[348px] lg:max-w-[368px] xl:max-w-[488px] 2xl:max-w-[528px]',
    '6': 'max-w-full md:max-w-[424px] lg:max-w-[448px] xl:max-w-[592px] 2xl:max-w-[640px]',
    '7': 'max-w-full md:max-w-[500px] lg:max-w-[528px] xl:max-w-[696px] 2xl:max-w-[752px]',
    '8': 'max-w-full lg:max-w-[608px] xl:max-w-[800px] 2xl:max-w-[864px]',
    '9': 'max-w-full lg:max-w-[688px] xl:max-w-[904px] 2xl:max-w-[976px]',
    '10': 'max-w-full lg:max-w-[768px] xl:max-w-[1008px] 2xl:max-w-[1088px]',
    '11': 'max-w-full lg:max-w-[848px] xl:max-w-[1112px] 2xl:max-w-[1200px]',
    '12': 'max-w-full',
  }
  return (
    <div className={cn('mx-auto', options[size], className)}>{children}</div>
  )
}
